import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/mdxLayout/MdxLayout.js";
import Img from "gatsby-image";
import { Link, graphql } from "gatsby";
import CopyPaste from "../../../components/copyPaste/CopyPaste.js";
export const query = graphql`
  query {
    appEmbedImg: file(relativePath: { eq: "appEmbed.png" }) {
      childImageSharp {
        fixed(quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    addBlockImg: file(relativePath: { eq: "addBlock.png" }) {
      childImageSharp {
        fixed(quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    sizifyBlockImg: file(relativePath: { eq: "selectSizifyBlock.png" }) {
      childImageSharp {
        fixed(quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    moveBlockImg: file(relativePath: { eq: "moveSizifyButton.png" }) {
      childImageSharp {
        fixed(quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Manually install widget`}</h1>
    <p>{`Sizify widget will automatic install on most basic themes. However, for some themes manual installation is required.`}</p>
    <h2>{`Option:1 (Recommended)`}</h2>
    <p>{`First, in the Theme Editor go to Theme Settings. Ensure Sizify app is toggled to the "On" position`}</p>
    <Img className="mdxLayout-img" fixed={props.data.appEmbedImg.childImageSharp.fixed} mdxType="Img" />
    <p>{`Next navigate to a product page. Then click "Add Block".`}</p>
    <Img className="mdxLayout-img" fixed={props.data.addBlockImg.childImageSharp.fixed} mdxType="Img" />
    <p>{`Next, Select "Sizify Button".`}</p>
    <Img className="mdxLayout-img" fixed={props.data.sizifyBlockImg.childImageSharp.fixed} mdxType="Img" />
    <p>{`Drag & drop button to your desired location on the page`}</p>
    <Img className="mdxLayout-img" fixed={props.data.moveBlockImg.childImageSharp.fixed} mdxType="Img" />
    <p>{`Done!`}</p>
    <p>{`If widget is still not appearing, try option #2 (below).`}</p>
    <h2>{`Option:2`}</h2>
    <CopyPaste id={'{% if product.metafields.sizify != blank %} <p class="sizify-button"></p>{% else %}{% for collection in product.collections %}{% unless collection.metafields.sizify == blank %}<p class="sizify-button"></p>{% break %}{% endunless %}{% endfor %} {% endif %}'} text="First copy code below:" mdxType="CopyPaste" />
    <p>{`Paste code into your theme (where appropriate). Where to paste code will vary depending on your theme.`}</p>
    <p>{`Done.`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      